import { Injectable } from '@angular/core'
import { createClient } from '@supabase/supabase-js'
import { environment } from '@env/environment'

const supabaseUrl = environment.supabaseUrl
const supabaseKey = environment.supabaseKey // Or use a JWT token for auth

@Injectable({
    providedIn: 'root', // Makes it available throughout the application
})
export class SupabaseService {
    private client = createClient(supabaseUrl, supabaseKey)

    getClient() {
        return this.client
    }
}
